export const goToPrevStep = (currentStep, action) => {
  if (currentStep > 1) {
    action((currentStep) => currentStep - 1);
  }
  if (window.matchMedia('(max-width: 500px)').matches) {
    window.scrollTo(0, 0);
  }
};

export const goToNextStep = (currentStep, numberOfSteps = 5, action) => {
  if (currentStep < numberOfSteps) {
    action((currentStep) => currentStep + 1);
  }
  if (window.matchMedia('(max-width: 500px)').matches) {
    window.scrollTo(0, 0);
  }
};
