import React from 'react';
import './index.css';
import '../src/assets/sass/index.scss';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import FormDataProvider from './context/FormDataContext';
import StepsInfoProvider from './context/StepsInfoContext';
import TranslationsProvider from './context/TranslationsContext';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // automatically requests fresh data in the background if user leaves the app and returns to stale data
      retry: false, // if true, failed queries will retry infinitely
      refetchOnReconnect: false, // if true, refetch on mount if the data is stale
      staleTime: 5 * 60 * 1000, // the time in milliseconds after data is considered stale. Defaults to 0
      refetchOnMount: 'always',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TranslationsProvider>
        <StepsInfoProvider>
          <FormDataProvider>
            <App />
          </FormDataProvider>
        </StepsInfoProvider>
      </TranslationsProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
