import React, { useContext, useState } from 'react';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';

import Input from '../UI/Input/Input';

import './DescriptionField.scss';

const DescriptionField = () => {
  const { formData, setFormData } = useContext(FormDataContext);
  const { translations, activeLocale } = useContext(TranslationsContext);
  const [shrink, setShrink] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      info: {
        ...formData.info,
        description: e.target.value,
      },
    });
  };

  return (
    <div className="input-wrapper">
      <Input
        placeholder={renderTranslation(
          activeLocale,
          translations,
          'step_three.description_placeholder'
        )}
        multiline
        type="textArea"
        onBlur={() => setShrink(!!formData.info.description.length)}
        onFocus={() => setShrink(true)}
        InputLabelProps={{ sx: { color: '#34B9B9!important' }, shrink }}
        label={renderTranslation(activeLocale, translations, 'step_three.description_label')}
        variant="standard"
        value={formData?.info?.description}
        textarea
        id="job-description"
        onChange={(e) => handleChange(e)}
        className="description-field"
        name="job-description"
        rows={5}
      />
    </div>
  );
};

export default DescriptionField;
