/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';

import TimeCheckboxes from '../TimeCheckboxes/TimeCheckboxes';
import FullCalendar from '../FullCalendar/FullCalendar';

import { getFormattedDate, getFormattedTodaysDate } from '../../utils/getFormattedDate';
import { backendCall } from '../../api/backendCall';
import { FormDataContext } from '../../context/FormDataContext';

import calendarMoreIcon from '../../../src/assets/zoofy/images/calendar-more.svg';

import './DateSliderAndCalendar.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import { makeDateArrayRange } from "../../utils/makeDateArrayRange";
import { getNextDays } from "../../utils/nextDay";
import { renderTranslation } from "../../utils/renderTranslation";
import { TranslationsContext } from "../../context/TranslationsContext";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadingSpinner from "../UI/LoadingSpinner/LoadingSpinner";

const DateSliderAndCalendar = () => {
	const { formData, setFormData } = useContext(FormDataContext);
	const [dateRanges, setDateRanges] = useState([]);
	const currentTaskId = formData?.currentTask?.task_id;
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDateIdentifier, setSelectedDateIdentifier] = useState(
		formData?.selectedDateIdentifier || ''
	);
	const [showTimeCheckboxes, setShowTimeCheckboxes] = useState(false);
	const [loadMoreDates, setLoadMoreDates] = useState(false);

	const [isTodayClicked, setIsTodayClicked] = useState(formData?.isTodayClicked);
	const { translations, activeLocale } = useContext(TranslationsContext);

	useEffect(() => {
		formData?.selectedDateIdentifier !== undefined &&
		setIsTodayClicked(formData?.selectedDateIdentifier === getFormattedTodaysDate());
	}, []);

	useEffect(() => {
		if (formData.taskRates.pickedSlots.length > 0) {
			setShowTimeCheckboxes(true);
		}
		setDateRanges(formData.dateRanges)
	}, []);

	const today = new Date();
	const hoursWithInitialZero = (today.getHours() < 10 ? '0' : '') + today.getHours(); // Needed because
	const minutesWithInitialZero = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
	const secondsWithInitialZero = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();

	const currentTime =
		hoursWithInitialZero + ':' + minutesWithInitialZero + ':' + secondsWithInitialZero;

	const fetchCurrentTaskRates = () => {
		setLoadMoreDates(true);
		return backendCall(
			`/task-rates/
			${formData.currentTask.task_id}
			/locations/
			${formData.address.lat},
			${formData.address.lon}
			?from=
			${getFormattedTodaysDate()}
			T${currentTime}Z`,
			'GET',
			null,
			''
		);
	};

	const fetchCurrentTaskRatesSuccess = (data) => {
		setFormData({
			...formData,
			taskRates: {
				...formData.taskRates,
				allTaskRates: data,
			},
		});
		setLoadMoreDates(false);
	};

	const { isLoading } = useQuery(
		`${currentTaskId}`,
		typeof currentTaskId === 'number' && fetchCurrentTaskRates,
		{
			onSuccess: fetchCurrentTaskRatesSuccess,
			onError: (error) => {
				setLoadMoreDates(false);
				console.log('Error:');
				console.log(error);
			},
		}
	);

	const handleSlideClick = (e) => {
		setSelectedDateIdentifier([e.target.getAttribute('date')]);

		setFormData({
			...formData,
			selectedDateIdentifier: [e.target.getAttribute('date')],
		});
		setShowTimeCheckboxes(true);
		setIsTodayClicked(e.target.getAttribute('date') === getFormattedTodaysDate());
	};

	const handleCalendarDayClick = (date) => {
		setSelectedDateIdentifier(date);

		setFormData({
			...formData,
			selectedDateIdentifier: date,
		});
		setShowTimeCheckboxes(true);

		setIsTodayClicked(date[0] === getFormattedTodaysDate());
	};

	const getNewMonth = useMutation({
		mutationFn: async (nextSliderDay) => {
			return await backendCall(
				`/task-rates/
				${formData.currentTask.task_id}
				/locations/
				${formData.address.lat},
				${formData.address.lon}
				?from=
				${getFormattedDate(nextSliderDay)}
				T${currentTime}Z`,
				'GET',
				null,
				''
			);
		},
		onSuccess: (data) => {
			const allDates = [...formData.taskRates.allTaskRates?.dates];
			data.dates.forEach((singleDate) => {
				allDates.push(singleDate);
			});
			setFormData({
				...formData,
				taskRates: {
					...formData.taskRates,
					allTaskRates: {
						...formData.taskRates.allTaskRates,
						dates: allDates,
					},
				},
			});
			setLoadMoreDates(false);
		},
		onError: (error) => {
			setLoadMoreDates(false);
			console.log('Error:');
			console.log(error);
		},
	});

	const handleEndOfSlider = async () => {
		const nextSliderDay = new Date(
			formData.taskRates.allTaskRates.dates[
			formData.taskRates.allTaskRates.dates?.length - 1
				].date.substring(0, 10)
		);

		nextSliderDay.setDate(nextSliderDay.getDate() + 1);

		getNewMonth.mutate(nextSliderDay);
	};

	const handleNextMonthClick = async (action, nextMonth) => {
		if (action === 'next') {
			setLoadMoreDates(true);
			try {
				const newTaskRates = await backendCall(
					`/task-rates/
					${formData.currentTask.task_id}
					/locations/
					${formData.address.lat},
					${formData.address.lon}
					?from=
					${getFormattedDate(nextMonth)}
					T${currentTime}Z`,
					'GET',
					null,
					''
				);
				const allDates = [...formData.taskRates.allTaskRates.dates];

				newTaskRates.dates.forEach((singleDate) => {
					allDates.push(singleDate);
				});

				setFormData({
					...formData,
					taskRates: {
						...formData.taskRates,
						allTaskRates: {
							...formData.taskRates.allTaskRates,
							dates: allDates,
						},
					},
				});
				setLoadMoreDates(false);
			} catch (error) {
				setLoadMoreDates(false);
				console.log('Error retreiving the new task rates:');
				console.log(error);
			}
		}
	};

	const handleCalendarClick = () => {
		// setShowTimeCheckboxes(true)
		setShowCalendar(true);
		setIsTodayClicked(false);

		const date = getNextDays();

		handleCalendarDayClick([moment(date).utc().format('YYYY-MM-DD')])
	};

	const handleSelectRange = (date) => {
		const dateRangesCopy = [...dateRanges];
		let inRange = false;

		dateRangesCopy.map((range, index) => {
			if (range.length === 1 && date.length === 1 && range[0] === date[0]) {
				inRange = true;
			} else if (date.length === 1 && range.filter((rangeDay) => date[0] === rangeDay).length === 1) {
				inRange = true;
				const splitRange = range;
				dateRangesCopy.splice(index, 1);
				splitRange.map((date) => dateRangesCopy.push([date]));
			}
		});

		!inRange && dateRangesCopy.push(date)
		setDateRanges(() => ([...dateRangesCopy]));

		setFormData((prevState) => ({
			...prevState,
			dateRanges: [...dateRangesCopy]
		}));
	}

	if (isLoading || loadMoreDates || !formData?.taskRates?.allTaskRates) return <LoadingSpinner/>;

	return (
		<div className={`calendar-wrapper-box ${showCalendar ? 'calendar-wrapper-rel' : ''}`}>
			{showCalendar && <div className="close-calendar" onClick={() => setShowCalendar(false)}>
				&#10006;
			</div> }
			{showCalendar ? (
				<FullCalendar
					dateRanges={dateRanges}
					selectedDateIdentifier={selectedDateIdentifier}
					handleCalendarDayClick={handleCalendarDayClick}
					showTimeCheckboxes={() => setShowTimeCheckboxes(true)}
					hideCalendar={() => setShowCalendar(false)}
					handleNextMonthClick={(action, nextMonth) => handleNextMonthClick(action, nextMonth)}
				/>
			) :
					<Swiper
						className="date-slider"
						width={350}
						slidesPerView={3}
						spaceBetween={25}
						onReachEnd={() => handleEndOfSlider()}
						breakpoints={{
							370: {
								width: 370,
								slidesPerView: 3,
								spaceBetween: 25,
							},
							576: {
								width: 576,
								slidesPerView: 4,
								spaceBetween: 25,
							},
							991: {
								width: 991,
								spaceBetween: 25,
								slidesPerView: 8,
							},
						}}
					>
						{formData.taskRates?.allTaskRates?.dates?.map((singleDay, i) => {
							const singleDayIdentifier = singleDay.date.substring(0, 10);

							const weekdayLabel = renderTranslation(
								activeLocale,
								translations,
								`month_${moment(singleDay.date).format('dddd').toLowerCase()}`
							)?.substring(0, 3);
							const monthLabel = renderTranslation(
								activeLocale,
								translations,
								`month_${moment(singleDay.date).format('MMMM').toLowerCase()}`
							)?.substring(0, 3);

							const dayNumberLabel = singleDay.date.substring(8, 10);
							let isSlotSelected = false;

							const singleSlot = formData?.taskRates?.pickedSlots.find(
								(slot) => slot.date === singleDayIdentifier
							);

							if (singleSlot && (singleSlot.morning || singleSlot.afternoon || singleSlot.evening))
								isSlotSelected = true;

							if (i > 4) return
							return (
								<SwiperSlide key={`day${i + 1}`} onClick={(e) => handleSlideClick(e)}>
									<div
										id={`day${i + 1}`}
										className={`single-date flex flex--column flex--center ${
											isSlotSelected ? 'selected-day' : ''
										} ${
											showTimeCheckboxes && singleDayIdentifier === selectedDateIdentifier[0]
												? 'single-date--active'
												: ''
										}`}
										date={singleDay.date.substring(0, 10)}
									>
										<span className="single-date__day">{weekdayLabel}</span>
										<span className="single-date__number">{dayNumberLabel}</span>
										<span className="single-date__month">{monthLabel}</span>
									</div>
								</SwiperSlide>
							);
						})}
						<SwiperSlide key="day-load-more" onClick={handleCalendarClick}>
							<div key="day-load-more" className="single-date  single-date__more-img flex flex--column flex--center">
								<img
									style={{ cursor: 'pointer' }}
									width={40}
									height={40}
									src={calendarMoreIcon}
									alt="Open Calendar to select multiple dates"
								/>
								<span className="single-date__more ">{renderTranslation(
									activeLocale,
									translations,
									`step2.date-picker.see-more-dates`
								)}</span>
							</div>
						</SwiperSlide>
					</Swiper>
			}
			<TimeCheckboxes
				handleSelectRange={handleSelectRange}
				showCalendar={showCalendar}
				isVisible={showTimeCheckboxes}
				currentTaskRates={formData.taskRates.allTaskRates}
				date={makeDateArrayRange(selectedDateIdentifier)}
				isToday={isTodayClicked}
			/>
		</div>
	);
};

export default React.memo(DateSliderAndCalendar);
