export const setCurrentLanguage = (language) => {
	const pathname = window.location.href
	const url = new URL(pathname);

	switch (language) {
		case 'nl':
			history.pushState({},'',`/be-nl${url.pathname}${url.search}`);
			break
		default:
			history.pushState({},'',`${url.pathname.replace('/be-nl/', '/')}${url.search}`);
	}
};
