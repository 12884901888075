export const getFormattedTodaysDate = () => {
  const date = new Date();

  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return year + '-' + month + '-' + day;
};

export const getFormattedDate = (string) => {
  const date = new Date(string);

  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return year + '-' + month + '-' + day;
};
