export const renderTranslation = (activeLocale, store, translationKey) => {
	let strings = {};
	if (activeLocale) {
		strings = store[activeLocale.toLowerCase()];
	}

	for (const [key, value] of Object.entries(strings)) {
		if (value.translation_key == translationKey) {
			return value.translation_value;
		}
	}

	return translationKey;
};
