import React, { FC, useContext } from 'react';
import { goToNextStep } from '../../utils/handleSteps';
import Button from '../UI/Button/Button';
import './StepButton.scss';

import { renderTranslation } from '../../utils/renderTranslation';

import { StepsInfoContext } from '../../context/StepsInfoContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { getFormattedTodaysDate } from '../../utils/getFormattedDate';

type StepsButtonsType = { handleSubmit?: (e: Event) => void; handleModal?: any, inputRef?: any };

const StepsButtons: FC<StepsButtonsType> = ({ handleSubmit, handleModal, inputRef }) => {
  const {
    numberOfSteps: { numberOfSteps },
    stepsInfo: { currentStep, setCurrentStep },
  } = useContext(StepsInfoContext);

  const { translations, activeLocale } = useContext(TranslationsContext);

  const { formData } = useContext(FormDataContext);

  const handleClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentStep === numberOfSteps) {
      handleSubmit?.(e);
    } else {
      const differentDatesSlots = formData?.taskRates?.pickedSlots.filter(
        (singlePickedSlot: any) =>
          !!singlePickedSlot.morning || !!singlePickedSlot.afternoon || !!singlePickedSlot.evening
      );


      if (currentStep ===  1 && !formData.enableBtn) {
        inputRef.focus();
        return;
      }
      if (currentStep === 3) {
        handleModal();
      }
      if (currentStep === 4) {
        formData.terms &&
          formData.validInfo &&
          goToNextStep(currentStep, numberOfSteps, setCurrentStep);
        return;
      }
      if (
        currentStep === 2 &&
        differentDatesSlots.length < 3 &&
        differentDatesSlots[0].date !== getFormattedTodaysDate()
      ) {
        handleModal(e);
      } else {
        goToNextStep(currentStep, numberOfSteps, setCurrentStep);
      }
    }
  };

  return (
    <div className={`button-wrapper`}>
      <Button
        handleClick={handleClick}
        className={`btn jobform-text step-button ${
          currentStep === numberOfSteps && 'step-button-small'
        } ${!formData.enableBtn ? (currentStep > 3 ? 'disabled-confirm' : 'disabled') : 'active'}`}
        disabled={currentStep > 1 && !formData.enableBtn}

      >
        {currentStep === numberOfSteps
          ? renderTranslation(activeLocale, translations, 'step_for_book')
          : renderTranslation(activeLocale, translations, 'next_step')}
      </Button>
    </div>
  );
};

export default StepsButtons;
