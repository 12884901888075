import React from 'react';
import './Modal.scss';
import { Modal } from '@mui/material';

const Modals = ({ isOpened, closeModal, children }) => {
  return (
    <Modal open={isOpened} onClose={closeModal}>
      {children}
    </Modal>
  );
};

export default Modals;
