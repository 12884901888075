import React, { useContext, useState } from 'react';
import Calendar from 'react-calendar';
import { getFormattedDate } from '../../utils/getFormattedDate';
import './FullCalendar.scss';
import { FormDataContext } from "../../context/FormDataContext";
import { getNextDays } from "../../utils/nextDay";
import { makeDateArrayRange } from "../../utils/makeDateArrayRange";
import moment from "moment";
import { getCurrentLanguage } from "../../utils/getCurrentLanguage";

const FullCalendar = ({
  showTimeCheckboxes,
  handleCalendarDayClick,
  handleNextMonthClick,
  dateRanges,
}) => {
	const [date, setDate] = useState();
	const { formData } = useContext(FormDataContext);

	const shouldDateBeSelected = (date) => {
		return formData.taskRates.pickedSlots.find((day) => day.date === getFormattedDate(date))
	}

	const handleDateClick = (value, event) => {
		const date = event.target.getAttribute("aria-label");
		const abbr = !date && event.target?.getElementsByTagName("abbr")[0].getAttribute("aria-label");
		const lastDate = date || abbr;

		showTimeCheckboxes();
		const formattedDatesArray = value?.map((singleDate) => {
			return getFormattedDate(singleDate);
		});

		if (formattedDatesArray[0] === formattedDatesArray[1]) {
			formattedDatesArray.splice(1, 1);
		}

		let inRange = false;
		makeDateArrayRange(value)?.forEach((singleDate) => {
			if (formData.taskRates.pickedSlots.find((day) => day.date === singleDate)) {
				inRange = true;
			}
		});

		setDate(inRange ? new Date(lastDate) : (formattedDatesArray[1] === '1970-01-01' ? new Date(lastDate) : value));
		handleCalendarDayClick(inRange ? [lastDate] : (formattedDatesArray[1] === '1970-01-01' ? [lastDate] : formattedDatesArray));
	};

	const handleDayClick = (value) => {
		showTimeCheckboxes();
		setDate(value);

		const formattedDatesArray = getFormattedDate(value);

		handleCalendarDayClick([formattedDatesArray]);
	};

	const checkRanges = (date) => {
		let inRange = 'react-calendar__tile--active';
		const formatDate = moment(date).format('YYYY-MM-DD');

		dateRanges.map((range) => {
			if (range.length === 1 && range[0] === formatDate) {
				return inRange = 'react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--inactive';
			}
			if (range.length > 1 && range[0] === formatDate) {
				return inRange = 'react-calendar__tile--rangeStart react-calendar__tile--active';
			}
			if (range.length > 1 && range[range.length - 1] === formatDate) {
				return inRange = 'react-calendar__tile--rangeEnd react-calendar__tile--active';
			}
		})
		return inRange;
	};


	const singleDate = () => {
		if ( date?.length === 2 &&  moment(date[0]).format('YYYY-MM-DD') ===  moment(date[1]).format('YYYY-MM-DD')) {
			return moment(date[0]).format('YYYY-MM-DD');
		} else {
			return  date?.length ? 'isRange' : moment(date).format('YYYY-MM-DD');
		}
	};

	console.log(dateRanges, 'dddddddddddddd')
	return (
		<div className="calendar-wrapper">
			<Calendar
				tileClassName={({ date }) => {
					const daySize = date.getDate().toString().length;
					const rangeStartEnd = checkRanges(date);
					const hideBorder = moment(date).format('YYYY-MM-DD') === singleDate()

					return shouldDateBeSelected(date) ?  `${rangeStartEnd} ${daySize === 1 && 'react-calendar__tile--rangeEnd--big'}`  : `${daySize === 1 && 'react-calendar__tile--rangeEnd--big'} ${hideBorder && 'react-calendar__tile--activeSingle'}`
				}}
				value={date}
				view="month"
				showNeighboringMonth={false}
				selectRange
				onActiveStartDateChange={({ action, activeStartDate }) =>
					handleNextMonthClick(action, activeStartDate)
				}
				formatLongDate={(locale, date) => getFormattedDate(date)}
				locale={getCurrentLanguage() || process.env.REACT_APP_DEFAULT_LOCALE}
				// defaultValue={getNextDays()}
				minDate={getNextDays()}
				maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
				onChange={(value, event,) => handleDateClick(value, event)}
				onClickDay={(value) => handleDayClick(value)}
				returnValue="range"
			/>
		</div>
	);
};

export default FullCalendar;
