import React, { useContext, useState } from 'react';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './WarrantyPackage.scss';

const WarrantyPackage = ({ id }) => {
  const { translations, activeLocale } = useContext(TranslationsContext);

  const { formData, setFormData } = useContext(FormDataContext);

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setFormData({
      ...formData,
      warrantyPackage: !formData.warrantyPackage,
    });
  };

  return (
    <div className="warranty-package m-top">
      <div className="warranty-package__checkbox flex">
        <label htmlFor={id} className="checkbox-container">
          <input
            type="checkbox"
            name="warranty-package"
            id={id}
            checked={!formData.warrantyPackage}
            onChange={handleChange}
          />
          <span className="custom-checkbox custom-warranty-checkbox"></span>
        </label>
        <span className="warranty-package-title">
          {`€4,95 ${renderTranslation(activeLocale, translations, 'step_four.dont_wont_package')}`}
        </span>
        <span className="insurance-promotion-label">Tip</span>
      </div>

      <div
        className={`warranty-package__accordion m-top--small ${
          isOpen ? 'warranty-package__accordion--opened' : ''
        }`}
      >
        <div
          className="warranty-package__accordion__top flex flex--center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="jobform-text">
            {renderTranslation(activeLocale, translations, 'step_four.insurance_about')}
          </span>
          <span className="warranty-plus">+</span>
        </div>
        <div className="warranty-package__accordion__bottom flex flex--column">
          <p className="warranty-package__accordion__bottom__title">
            {renderTranslation(activeLocale, translations, 'step_four.insurance_info_title')}
          </p>
          <p className="warranty-package__accordion__bottom__price">EUR 4,95</p>
          <ul className="warranty-package__accordion__bottom__list">
            <li> {renderTranslation(activeLocale, translations, 'step_four.insurance_one')}</li>
            <li> {renderTranslation(activeLocale, translations, 'step_four.insurance_two')}</li>
            <li> {renderTranslation(activeLocale, translations, 'step_four.insurance_three')}</li>
            <li> {renderTranslation(activeLocale, translations, 'step_four.insurance_four')}</li>
          </ul>
          <a
            className="warranty-package__accordion__bottom__link-to"
            href="https://zoofy.nl/garantie/"
            target="_blank"
            rel="noreferrer"
          >
            {renderTranslation(activeLocale, translations, 'step_four.insurance_link')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default WarrantyPackage;
