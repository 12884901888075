import React, { useState, useEffect, useContext } from 'react';

import { FormDataContext } from '../../context/FormDataContext';
import { backendCall } from '../../api/backendCall';

import { FaStar, FaStarHalf } from 'react-icons/fa';

import './CurrentTaskInfo.scss';
import { useMutation } from 'react-query';

const CurrentTaskInfo = () => {
  const { formData, setFormData } = useContext(FormDataContext);
  const [currentTaskInfo, setCurrentTaskInfo] = useState({});

  let ratingStars = [];
  if (currentTaskInfo?.info) {
    const reviewsAvg = currentTaskInfo?.info['reviews-avg'];

    for (let i = 0; i < 5; i++) {
      if (i < reviewsAvg && reviewsAvg - i >= 1) {
        ratingStars.push(1);
      } else {
        ratingStars.push(reviewsAvg > i ? 0.5 : 0);
      }
    }
  }

  const addressSearch = useMutation({
    mutationFn: async ({ id }) => await backendCall(`/task-reviews/${id}`, 'GET', null, ''),
    onSuccess: (response) => {

      setCurrentTaskInfo(response);

      setFormData((prev) => ({
        ...prev,
        currentTask: {
          ...prev.currentTask,
          ...response.task,
          task_id: response.task.id,
        },
        taskRates: {
          allTaskRates: {
            ...formData?.taskRates?.allTaskRates,
          },
          pickedSlots: [],
        },
      }));
    },
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const searchParams = new URLSearchParams(document.location.search);
        const taskId = searchParams.get('task');
        addressSearch.mutate({ id: taskId });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const StarRating = (ratingStars) => {
    return (
      <div className="current-task-info__stars flex ">
        {ratingStars?.map((i, index) => {
          return (
            <React.Fragment key={i + index}>
              {i === 1 && <FaStar key={i} color="#FED900"/>}
              {i === 0 && <FaStar key={i} color="#a7a7a7"/>}
              {i === 0.5 && (
                <div className="half-star-wrapper">
                  <FaStar key={i} color="#a7a7a7"/>
                  <FaStarHalf className="half-star" color="#FED900"/>
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    );
  };

  return null;

  // return (
  //   <div className="current-task-info flex flex-center">
  //     {addressSearch.isLoading ? (
  //       <LoadingDots />
  //     ) : (
  //       <>
  //         {StarRating(ratingStars)}
  //         <span className="current-task-info__text">
  //           {currentTaskInfo?.info
  //             ? formData.currentTask.singular +
  //               ' from €' +
  //               currentTaskInfo.task.price +
  //               ' ' +
  //               currentTaskInfo.task.rate_type.singular
  //             : ''}
  //         </span>
  //         {!!error?.length && <ErrorMessage text={error} />}
  //       </>
  //     )}
  //   </div>
  // );
};

export default CurrentTaskInfo;
