import React, { useContext, useEffect, useState } from 'react';
import { FormDataContext } from '../../context/FormDataContext';

import Input from '../UI/Input/Input';
import Select from '../UI/Select/Select';

import './DynamicFields.scss';

const DynamicFields = () => {
  const { formData, setFormData } = useContext(FormDataContext);
  const [dynamicFields, setDynamicFields] = useState([]);

  useEffect(() => {
    if (formData.dynamicFields?.length > 0) {
      setDynamicFields(formData.dynamicFields);
    }
  }, []);

  // This function runs either when an user clicks on an option
  // inside a Select component or when they type in an Input component
  const handleDynamicFieldsChange = (e) => {
    const dynamicFieldsNewArray = [...dynamicFields];

    const selectedFieldName = e.target.name;

    dynamicFieldsNewArray.forEach((singleDynamicField) => {
      if (singleDynamicField.title === selectedFieldName) {
        singleDynamicField.value = e.target.value;
      }
    });
    setFormData({
      ...formData,
      dynamicFields: dynamicFieldsNewArray,
    });
    setDynamicFields(dynamicFieldsNewArray);
  };

  return (
    <div className="dynamic-fields">
      {dynamicFields?.length > 0 &&
        dynamicFields.map((singleDynamicField) => {
          switch (singleDynamicField.type) {
            case 'select':
              return (
                <Select
                  key={singleDynamicField.id}
                  required={singleDynamicField.required === 1}
                  id={`dynamic-field-select-${singleDynamicField.id}`}
                  name={singleDynamicField.title}
                  label={singleDynamicField.placeholder}
                  value={singleDynamicField.value}
                  data-type="select"
                  data={singleDynamicField.values}
                  onChange={(e) => handleDynamicFieldsChange(e)}
                />
              );

            default:
              return (
                <Input
                  key={singleDynamicField.id}
                  type={singleDynamicField.type} // text or number
                  min={singleDynamicField.validation.min}
                  max={singleDynamicField.validation.max}
                  step={1}
                  required={singleDynamicField.required === 1}
                  isValid
                  id={`dynamic-field-input-${singleDynamicField.id}`}
                  name={singleDynamicField.title}
                  /*
                  placeholder={singleDynamicField.placeholder}
*/
                  label={singleDynamicField.title}
                  value={singleDynamicField.value}
                  onChange={(e) => handleDynamicFieldsChange(e)}
                />
              );
          }
        })}
    </div>
  );
};

export default DynamicFields;
