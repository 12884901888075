import React, { useState, createContext } from 'react';

// useContext hook to manage translations in global state
export const TranslationsContext = createContext();

const TranslationsProvider = ({ children }) => {
	const [translations, setTranslations] = useState({});
    const [activeLocale, setActiveLocale] = useState('')

	const store = { translations, setTranslations, activeLocale, setActiveLocale };

	return (
		<TranslationsContext.Provider value={store}>
			{children}
		</TranslationsContext.Provider>
	);
};

export default TranslationsProvider;
