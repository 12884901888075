import React, { useState, createContext } from 'react';
import { useMemo } from 'react';
import { generateSessionId } from "../utils/generateSessionId";

// useContext hook to manage submitted Form Data global state
export const FormDataContext = createContext();

const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const initForm = () => {
    setFormData({
      language: '',
      addressValue: '',
      address: {
        lat: '',
        lon: '',
      },
      operatingCountry: true,
      currentTask: {},
      taskValue: '',
      taskRates: {
        pickedSlots: [],
        allTaskRates: {},
      },
      date: {
        text: '',
        formattedFullDate: '',
      },
      time: '',
      dynamicFields: [],
      info: {
        description: '',
        uploads: [],
      },
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        contactEmail: '',
        phoneNumber: '',
      },
      warrantyPackage: true,
      isUrgentJob: false,
      enableBtn: false,
      validInfo: false,
      terms: false,
      bookingSuccess: false,
      isBookingError: false,
      dateRanges: [],
      sessionId: generateSessionId(),
      disableMultipleRangeModal: false,
    });
  };

  const store = useMemo(
    () => ({
      formData,
      setFormData,
      initForm,
    }),
    [formData]
  );

  return <FormDataContext.Provider value={store}>{children}</FormDataContext.Provider>;
};

export default FormDataProvider;
