import React, { useState, useContext } from 'react';

import Input from '../UI/Input/Input';
import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';

import 'react-phone-number-input/style.css';
import './CustomerInformationFields.scss';

const CustomerInformationFields = () => {
  const { formData, setFormData } = useContext(FormDataContext);
  const { activeLocale, translations } = useContext(TranslationsContext);

  // Fields Error Messages
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState(null);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(null);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState(null);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(null);

  const handleChange = (e) => {
    handleValidation(e);
    setFormData({
      ...formData,
      userData: {
        ...formData.userData,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleValidation = (e) => {
    if (e.target.name === 'firstName') {
      if (e.target.value?.length === 0) {
        setFirstNameErrorMessage(
          renderTranslation(activeLocale, translations, 'step4.valid_firstname.error_message')
        );
      } else {
        setFirstNameErrorMessage('');
      }
    } else if (e.target.name === 'lastName') {
      if (e.target.value?.length === 0) {
        setLastNameErrorMessage(
          renderTranslation(activeLocale, translations, 'step4.valid_lastname.error_message')
        );
      } else {
        setLastNameErrorMessage('');
      }
    } else if (e.target.name === 'email') {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        setEmailErrorMessage(
          renderTranslation(activeLocale, translations, 'step4.valid_email.error_message')
        );
        if (formData.userData.email !== e.target.value) {
          setContactEmailErrorMessage(
            renderTranslation(activeLocale, translations, 'Please enter same email address')
          );
        }
      } else {
        setEmailErrorMessage('');
      }
      if (
        formData.userData.contactEmail !== e.target.value &&
        formData.userData.contactEmail.length > 0
      ) {
        setContactEmailErrorMessage(
          renderTranslation(activeLocale, translations, 'Please enter same email address')
        );
      }
    } else if (e.target.name === 'contactEmail') {
      if (!/\S+@\S+\.\S+/.test(e.target.value) || formData.userData.email !== e.target.value) {
        setContactEmailErrorMessage(
          renderTranslation(activeLocale, translations, 'Please enter same email address')
        );
      } else {
        setContactEmailErrorMessage('');
      }
    } else if (e.target.name === 'phoneNumber') {
      if (e.target.value.length >= 8 && e.target.value.length <= 13) {
        setPhoneNumberErrorMessage('');
      } else {
        setPhoneNumberErrorMessage(
          renderTranslation(activeLocale, translations, 'step4.valid_phonenumber.error_message')
        );
      }
    }
  };

  return (
    <div className="customer-information-fields">
      <div className="customer-information-fields__form-group">
        <Input
          variant="standard"
          type="text"
          id="firstName"
          name="firstName"
          onBlur={(e) => handleValidation(e)}
          isValid={firstNameErrorMessage?.length === 0}
          errorMessage={firstNameErrorMessage}
          value={formData.userData.firstName}
          onChange={handleChange}
          label={`${renderTranslation(activeLocale, translations, 'step_four.first_name')}*`}
        />
        <Input
          variant="standard"
          type="text"
          id="lastName"
          name="lastName"
          onBlur={(e) => handleValidation(e)}
          isValid={lastNameErrorMessage?.length === 0}
          errorMessage={lastNameErrorMessage}
          value={formData.userData.lastName}
          onChange={handleChange}
          label={`${renderTranslation(activeLocale, translations, 'step_four.last_name')}*`}
        />
      </div>

      <div className="customer-information-fields__form-group">
        <Input
          variant="standard"
          type="text"
          id="email"
          name="email"
          isValid={emailErrorMessage?.length === 0}
          onBlur={(e) => handleValidation(e)}
          errorMessage={emailErrorMessage}
          value={formData.userData.email}
          onChange={handleChange}
          label={`${renderTranslation(activeLocale, translations, 'step_four.email_address')}*`}
        />
        <Input
          variant="standard"
          type="text"
          id="contactEmail"
          name="contactEmail"
          isValid={contactEmailErrorMessage?.length === 0}
          onBlur={(e) => handleValidation(e)}
          errorMessage={contactEmailErrorMessage}
          value={formData.userData.contactEmail}
          onChange={handleChange}
          label={`${renderTranslation(
            activeLocale,
            translations,
            'step_four.email_confirm_address'
          )}*`}
        />
      </div>

      <div className="customer-information-fields__form-group customer-information-fields__form-group--phone-number">
        <Input
          variant="standard"
          type="number"
          international
          id="phoneNumber"
          value={formData.userData.phoneNumber}
          isValid={phoneNumberErrorMessage?.length === 0}
          errorMessage={phoneNumberErrorMessage}
          onChange={handleChange}
          name="phoneNumber"
          onBlur={(e) => handleValidation(e)}
          label={`${renderTranslation(activeLocale, translations, 'step_four.telephone')}*`}
        />
      </div>
    </div>
  );
};

export default CustomerInformationFields;
