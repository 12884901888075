import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';

import Input from '../UI/Input/Input';
import ResultsDropdown from '../ResultsDropdown/ResultsDropdown';

import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';

import { backendCall } from '../../api/backendCall';

import './AddressField.scss';

import locationIcon from '../../assets/img/location-icon.png';
import locationIconBlue from '../../assets/img/icon-blue-location.png';
import informationIcon from '../../assets/img/info.png';
import ErrorMessage from '../UI/ErrorMessage/ErrorMessage';

import { useOutsideClick } from '../../utils/useOutsideClick.js';
import { useMutation } from 'react-query';
import debounce from 'lodash.debounce';
import { InputAdornment } from '@mui/material';

const AddressField = ({ searchAddressRef, searchFieldRef, setAddressRef }) => {
	const { translations, activeLocale } = useContext(TranslationsContext);

	const { formData, setFormData } = useContext(FormDataContext);

	const [showAddressDropdown, setShowAddressDropdown] = useState(false);
	const [isInputFocus, setIsInputFocus] = useState(false);
	const [inputValue, setInputValue] = useState(formData?.addressValue?.length || '');
	const [addressList, setAddressList] = useState([]);
	const [addressError, setAddressError] = useState('');

	const ref = useRef(null);
	useOutsideClick((e) => {
		e.target.id === 'tasks' && searchFieldRef.current.focus();

		if (isInputFocus) {
			e.preventDefault();
			setIsInputFocus(false);
			searchAddressRef.current.blur();
		}
	}, ref);

	useEffect(() => {
		setShrink(formData.addressValue.length > 2);
		setInputValue(formData.addressValue);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const addressList = ['street', 'suffix', 'postal_code', 'city', 'country', 'lat', 'lon'];
			let address = {};

			addressList.map((key) => {
				const addressItem = window.localStorage.getItem(key);
				if (!addressItem) {
					setFormData((prev) => ({
						...prev,
						address: null,
						addressValue: '',
					}));
					return;
				}
				address = { ...address, [key]: addressItem };

				setShrink(`${address.street} ${address.suffix} ${address.postal_code} ${address.city}`.length > 2)
				setInputValue(`${address.street} ${address.suffix} ${address.postal_code} ${address.city}`);
				setFormData((prev) => ({
					...prev,
					address: address,
					addressValue: `${address.street} ${address.suffix} ${address.postal_code} ${address.city}`,
					operatingCountry: true,
				}));
				/*
				sessionStorage.setItem('operatingCountry', info.addressObject.id.substring(0, 2));
*/
			});
		}
	}, []);

	const addressSearch = useMutation({
		mutationFn: (value) =>
			backendCall(
				'/api/v1/address-international?search=' + value,
				'GET',
				null,
				'gateway',
				null,
				false,
				formData.sessionId,
			),
		onSuccess: ({ data }) => {
			setAddressList(data);
			setShowAddressDropdown(true);
			setAddressError('');
		},
		onError: (err) => {
			setAddressList([]);
			setAddressError(err);
		},
	});

	const addressSearchById = useMutation({
		mutationFn: ({ inputValue, addressId }) =>
			backendCall(
				'/api/v1/address-international?search=' + inputValue + '&container=' + addressId,
				'GET',
				null,
				'gateway',
				null,
				false,
				formData.sessionId,
			),
		onSuccess: ({ data }) => {
			setAddressList(data);
		},
		onError: (error) => {
			setAddressList([]);
			setAddressError(error);
		},
	});

	const addressByObjectById = useMutation({
		mutationFn: async ({ addressObject, addressLine }) => {
			return {
				response: await backendCall(
					'/api/v1/address-international/' + addressObject.id,
					'GET',
					null,
					'gateway',
					null,
					false,
					formData.sessionId,
				),
				info: { addressObject, addressLine },
			};
		},
		onSuccess: ({ response, info }) => {
			const { city, country } = response;
			if (city && country) {
				setIsInputFocus(false);
				setAddressList([]);
				if (country === 'Netherlands' || country === 'Belgium') {
					for (const [key, value] of Object.entries(response)) {
						window.localStorage.setItem(key, value);
					}

					setFormData((prev) => ({
						...prev,
						address: response,
						addressValue: info.addressLine,
						operatingCountry: true,
					}));
					sessionStorage.setItem('operatingCountry', info.addressObject.id.substring(0, 2));
				} else {
					setFormData((prev) => ({
						...prev,
						operatingCountry: false,
					}));
				}
			}
		},
		onError: (error) => {
			setAddressList([]);
			setAddressError(error);
		},
	});

	const debouncedSearch = useCallback(
		debounce(async (e) => {
			if (
				e.target.value?.length > 2 &&
				e.target.value.charAt(0) !== ' ' &&
				e.target.value.charAt(1) !== ' '
			) {
				await addressSearch.mutate(e.target.value);
			} else {
				setAddressList([]);
			}
		}, 500),
		[]
	);

	const searchAddress = async (e) => {
		setShrink(e.target.value.length > 2)
		setInputValue(e.target.value);

		if (!e.target.value) {
			setFormData((prev) => ({
				...prev,
				address: null,
				addressValue: '',
			}));
		}
		debouncedSearch(e);
	};

	const debouncedSearchById = useCallback(
		debounce(async ({ inputValue, addressId }) => {
			await addressSearchById.mutate({ inputValue, addressId });
		}, 500),
		[]
	);

	const handleAddressClick = async (address) => {
		const trimmedAddressLine = address?.AddressLine.replace(' -', '');

		setShowAddressDropdown(true);
		setIsInputFocus(true);
		setShrink(trimmedAddressLine.length > 2)
		setInputValue(trimmedAddressLine);
		if (address.Type.toLowerCase() === 'address') {
			getAddress(address, trimmedAddressLine);
		} else {
			debouncedSearchById({ inputValue, addressId: address.id });
		}
	};

	const debouncedSearchByObjectById = useCallback(
		debounce(async ({ addressObject, addressLine }) => {
			await addressByObjectById.mutate({ addressObject, addressLine });
		}, 500),
		[]
	);

	const getAddress = async (addressObject, addressLine) => {
		debouncedSearchByObjectById({ addressObject, addressLine });
		// const { address } = addressResponse.data;
		// const { city, country } = address;
	};

	const [shrink, setShrink] = useState(false);

	return (
		<>
			<div className="address-field flex flex--center" ref={ref}>
				<div className="address-field__input-container">
					<Input
						inputRef={(input) => {
							if (input) {
								formData?.addressValue?.length && input.focus();
								setAddressRef(input);
							}
						}}
						InputLabelProps={{ sx: shrink ? inputValue.length > 2 ? { color: '#34B9B9!important' } : { with: 0, color: 'black!important'} : { pl: 3, pr: 3, color: '#34B9B9!important' }, shrink }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<img
										width="15"
										className="address-field__location-icon"
										src={formData.addressValue === '' ? locationIcon : locationIconBlue}
										alt="Choose your location"
									/>
								</InputAdornment>
							),
						}}
						label={inputValue.length > 2 ? renderTranslation(activeLocale, translations, 'step_address') : ''}
						variant="outlined"
						ref={searchAddressRef}
						type="text"
						id="address"
						className="address-field__input"
						name="address"
						placeholder={renderTranslation(activeLocale, translations, 'step_address_placeholder')}
						value={inputValue}
						onBlur={(e) => setShrink(!!e.target.value)}
						onFocus={() => {
							setShrink(true);
							setIsInputFocus(true);
						}}
						onChange={searchAddress}
					/>
					<ResultsDropdown
						data={addressList}
						opened={
							addressSearchById.isLoading ||
							addressByObjectById.isLoading ||
							(showAddressDropdown && isInputFocus && inputValue !== '' && addressList?.length > 0)
						}
						handleClick={handleAddressClick}
					/>
				</div>
				<img
					width={20}
					height={20}
					className="address-field__info-icon"
					src={informationIcon}
					alt="Get Info about Address Field"
				/>
				<span className="address-field__info-message jobform-text">
          {renderTranslation(activeLocale, translations, 'jobform.addressInfoMessage')}
        </span>
			</div>
			{!!addressError?.length && <ErrorMessage text={addressError}/>}
			{!formData.operatingCountry && (
				<ErrorMessage
					text={renderTranslation(activeLocale, translations, 'step1.not-operating-country')}
				/>
			)}
		</>
	);
};

export default AddressField;
