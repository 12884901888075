import React, { useContext } from 'react';

import { TranslationsContext } from '../../../context/TranslationsContext';
import { renderTranslation } from '../../../utils/renderTranslation';

const Select = ({
	label,
	name,
	errorMessage,
	data,
	value,
	required,
	onChange,
	...selectProps
}) => {
	// Retrieve Translations from Global Context
	const { translations, activeLocale } = useContext(TranslationsContext);

	return (
		<div className="select-component">
			{label && label?.length > 0 && selectProps.id ? (
				<label className="jobform-text" htmlFor={selectProps.id}>
					{label}
				</label>
			) : null}

			<select
				required={required}
				name={name}
				value={value}
				onChange={onChange}
				{...selectProps}
			>
				<option hidden value="">
					{renderTranslation(
						activeLocale,
						translations,
						'select.field.placeholder'
					)}
				</option>
				{data.map(singleOption => (
					<option key={singleOption.id} value={singleOption.value}>
						{singleOption.title}
					</option>
				))}
			</select>

			{errorMessage && errorMessage?.length > 0 ? (
				<span className="jobform-text">{errorMessage}</span>
			) : null}
		</div>
	);
};

export default Select;
