import React, { FC, useContext } from 'react';
import { FormDataContext } from "../../context/FormDataContext";
import { goToNextStep } from '../../utils/handleSteps';
import { StepsInfoContext } from '../../context/StepsInfoContext';
import { renderTranslation } from '../../utils/renderTranslation';
import { TranslationsContext } from '../../context/TranslationsContext';
import Button from '../UI/Button/Button';
import Modals from '../UI/Modal/Modal';

import tipImage from '../../assets/zoofy/images/tips.png';

import './SelectDateModal.scss';

type SelectedModalProps = { isOpened: boolean; closeModal: () => void };

const SelectDateModal: FC<SelectedModalProps> = ({ isOpened, closeModal }) => {
  const {
    numberOfSteps: { numberOfSteps },
    stepsInfo: { currentStep, setCurrentStep },
  } = useContext(StepsInfoContext);

  const { translations, activeLocale } = useContext(TranslationsContext);
  const { setFormData } = useContext(FormDataContext);

  const handleChange = () => {
    setFormData((prevState: { disableMultipleRangeModal: boolean; }) => ({
      ...prevState,
      disableMultipleRangeModal: true,
    }));
    goToNextStep(currentStep, numberOfSteps, setCurrentStep);
};

  return (
    <Modals isOpened={isOpened} closeModal={closeModal}>
      <div className={`select-date-modal ${isOpened ? ' select-date-modal--opened' : ''}`}>
        <div className="select-date-modal__content">
          <div className="select-date-modal__upper">
            <div className="tip-wrapper">
              <div>
                <img width={22} src={tipImage} alt="tip Image" className="tip-image" />
              </div>
              <h4 className="tip-title">Tip</h4>
            </div>
            <Button
              type="button"
              className="close"
              data-dismiss="modal"
              handleClick={handleChange}
            >
              <span aria-hidden="true">×</span>
            </Button>
          </div>
          <div className="select-date-modal__lower">
            <div className="modal-body">
              <p className="body-main" style={{ fontFamily: 'Helvetica' }}>
                {renderTranslation(activeLocale, translations, 'step_two.fast_way')}
              </p>
              <p className="body-secondary" style={{ fontFamily: 'Helvetica' }}>
                {renderTranslation(activeLocale, translations, 'step_two.select_more')}
              </p>
            </div>
            <div className="button-tip-wrapper">
              <button onClick={() => closeModal()} className="secondary-cta jobform-text">
                {renderTranslation(activeLocale, translations, 'step_two.popup_cta_1')}
              </button>
              <div
                style={{ fontFamily: 'Helvetica' }}
                onClick={() => goToNextStep(currentStep, numberOfSteps, setCurrentStep)}
                className="select-more-dates-close-button"
                data-dismiss="modal"
              >
                {renderTranslation(activeLocale, translations, 'step_two.popup_no')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modals>
  );
};

export default SelectDateModal;
