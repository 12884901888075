import React, { FC } from 'react';

import './StepTitle.scss';

type StepTitleType = {
  htmlElement: any | string;
  children: JSX.Element;
  customClass?: string;
};

const StepTitle: FC<StepTitleType> = ({ htmlElement, children, customClass }) => {
  const Tag = htmlElement;

  return (
    <div className="step-title flex flex--center">
      <Tag className={`step-title__text jobform-text ${(customClass && customClass) || ''}`}>
        {children}
      </Tag>
    </div>
  );
};

export default StepTitle;
