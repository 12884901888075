import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import JobFormScreen from './components/Screens/JobFormSCreeen';

const languagesPath = () => {
  const pathname = window.location.pathname;
  const locale = pathname.split('/')[1];

  switch (locale) {
    case 'be-nl':
      return '/be-nl/:name' ;
    default:
      return '/:name';
  }
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path={languagesPath()} element={<JobFormScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
