import React, { forwardRef, useEffect, useState } from 'react';
import './Input.scss';
import { TextField } from '@mui/material';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Input = forwardRef(
	(
		{
			type,
			label,
			required,
			textarea,
			min,
			isValid,
			max,
			step,
			pattern,
			variant,
			onBlur,
			onFocus,
			errorMessage,
			setRef,
			InputLabelProps,
			...inputProps
		},
		ref
	) => {
		const [focused, setFocused] = React.useState(false);
		const [shrink, setShrink] = useState(false);

		useEffect(() => {
			setRef?.(ref);
		}, []);

		return (
			<div
				className={`input-component ${
					!errorMessage?.length > 0 &&
					errorMessage !== null &&
					variant === 'standard' &&
					'input-active'
				}`}
			>
				<TextField
					InputLabelProps={InputLabelProps || { sx: shrink ? { color: '#34B9B9!important' } : {} }}
					error={errorMessage?.length}
					label={label}
					variant={variant}
					type={type}
					className={!isValid && 'error'}
					required={required}
					ref={ref}
					onBlur={(e) => {
						setShrink(false);
						setFocused(false);
						onBlur?.(e);
					}}
					onFocus={(e) => {
						setShrink(true);
						setFocused(true);
						onFocus?.(e);
					}}
					min={min && type === 'number' ? min : null}
					max={max && type === 'number' ? max : null}
					step={step && type === 'number' ? step : null}
					pattern={pattern ? pattern : null}
					{...inputProps}
				/>
				{errorMessage?.length > 0 && !focused ? <ErrorMessage text={errorMessage}/> : null}
			</div>
		);
	}
);

Input.displayName = 'Input';

export default Input;
