import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import UploadedFilesBox from '../UploadedFilesBox/UploadedFilesBox';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';
import { backendCall } from '../../api/backendCall';

import './UploadImageField.scss';

const MAX_UPLOAD_SIZE = 1048576; // In byte (binary). Converted, it is 1MB

const UploadImageField = () => {
  // Retrieve Translations from Global Context
  const { translations, activeLocale } = useContext(TranslationsContext);

  // Retrieve FormData from Global Context
  const { formData, setFormData } = useContext(FormDataContext);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [fileLoader, setFileLoader] = useState({
    isLoading: false,
    file: {},
  });

  useEffect(() => {
    if (!sessionStorage.getItem('img-uuid')) {
      const newUUID = uuidv4();
      sessionStorage.setItem('img-uuid', newUUID);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'application/pdf': ['.pdf'],
    },

    onDrop: useCallback(
      async (acceptedFiles, fileRejections) => {
        const tempUploadedFiles = [...formData.info.uploads];

        const acceptedFilesWithSrc = acceptedFiles.map((file) => {
          return Object.assign(file, {
            src: URL.createObjectURL(file),
          });
        });

        // Handle sending each uploaded file to Media Service API
        const sendFileToMediaService = async (file, tempUploadedFiles) => {
          const formdata = new FormData();
          formdata.append('type', 'Appointment');
          formdata.append('ref_id', sessionStorage.getItem('img-uuid'));
          formdata.append('file', file);

          const myHeaders = new Headers();

          myHeaders.append('SERVICE-KEY', process.env.APP_MEDIA_SERVICE_KEY);

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
          };

          setLoading(true);
          fetch(`${process.env.REACT_APP_MEDIA_URL}/api/v1/media/upload`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);
              if (data && data.data) {
                setErrorMessage('');
                tempUploadedFiles.push(data.data);
                setFormData((prev) => ({
                  ...prev,
                  info: {
                    ...prev.info,
                    uploads: tempUploadedFiles,
                  },
                }));
              } else {
                setErrorMessage('ERROR UPLOADING MEDIA');
              }
            })
            .catch((error) => {
              setLoading(false);
              setErrorMessage(error);
            });
        };

        acceptedFilesWithSrc.map(async (singleFile) => {
          await sendFileToMediaService(singleFile, tempUploadedFiles);
        });

        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              setErrorMessage(
                renderTranslation(activeLocale, translations, 'step_3.upload-error.file-too-large')
              );
            } else if (err.code === 'file-invalid-type') {
              setErrorMessage(
                renderTranslation(activeLocale, translations, 'step_3.upload-error.file-invalid')
              );
            }
          });
        });
      },
      [formData.info.uploads]
    ),
    maxSize: MAX_UPLOAD_SIZE,
  });

  const handleRemoveFile = async (file) => {
    setFileLoader({
      isLoading: true,
      file: file,
    });
    try {
      const deleteResponse = await backendCall(
        `/api/v1/media/${file.uuid}`,
        'DELETE',
        null,
        'media',
        {
          'SERVICE-KEY': process.env.REACT_APP_MEDIA_SERVICE_KEY,
          'Content-type': 'multipart/form-data',
        }
      );
      if (deleteResponse?.status.toLowerCase() === 'success') {
        const filterFiles = formData.info.uploads.filter(
          (singleFile) => singleFile.uuid !== file.uuid
        );
        setFormData({
          ...formData,
          info: {
            ...formData.info,
            uploads: filterFiles,
          },
        });
        setFileLoader({
          isLoading: false,
          file: {},
        });
        setErrorMessage('');
      }
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <UploadedFilesBox
      open={open}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      maxUploadSize={MAX_UPLOAD_SIZE}
      handleRemoveFile={handleRemoveFile}
      errorMessage={errorMessage}
      isUpload={loading}
      isFileLoading={fileLoader}
    />
  );
};

export default UploadImageField;
