import React, { useContext, useEffect, useRef, useState } from 'react';

import AddressField from '../../components/AddressField/AddressField';
import CurrentTaskInfo from '../../components/CurrentTaskInfo/CurrentTaskInfo';
import StepsButtons from '../../components/StepsButtons/StepsButtons';
import StepTitle from '../../components/UI/StepTitle/StepTitle';

import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './Step-1.scss';

import jf_gammalogo from '../../assets/zoofy/images/jf_gammalogo.jpg';
import jf_hornbachlogo from '../../assets/zoofy/images/jf_hornbachlogo.png';
import jf_praxislogo from '../../assets/zoofy/images/jf_praxislogo.png';
import jf_bollogo from '../../assets/zoofy/images/jf_bollogo.png';
import { backendCall } from '../../api/backendCall';

const Step1 = () => {
  const { translations, activeLocale } = useContext(TranslationsContext);
  const { formData, setFormData } = useContext(FormDataContext);
  const [addressRef, setAddressRef] = useState(null);

  const searchAddress = useRef(null);
  const searchField = useRef(null);

  const fetchDynamicFields = async (id) => {
    try {
      const response = await backendCall(`/tasks/${id}`, 'GET', null, '');
      console.log(response, 'response');

      setFormData((prev) => ({
        ...prev,
        dynamicFields: response?.dynamicFields?.length ? response.dynamicFields : [],
        currentTask: {
          ...prev.currentTask,
          singular: response.singular,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const searchParams = new URLSearchParams(document.location.search);
        const taskId = searchParams.get('task');
        fetchDynamicFields(taskId);
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  // useEffect(() => {
  //   // Send a custom event
  //   ReactGA.event({
  //     category: 'Zoofy.com Jobform Steps',
  //     action: 'Jobform Step 1 - Address & Task',
  //     nonInteraction: true,
  //   });
  // }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      enableBtn: !!(
        formData?.address?.city &&
        formData?.currentTask?.task_id &&
        formData.operatingCountry
      ),
      taskRates: formData.taskValue
        ? { ...formData.taskRates }
        : { allTaskRates: {}, pickedSlots: [] }, // if task is changed, cancel previous task rates and picked slots from global state
      dynamicFields: formData.currentTask?.task_id ? [...formData.dynamicFields] : [],
    }));
  }, [
    formData.address?.street,
    formData.currentTask?.task_id,
    formData.operatingCountry,
    formData.taskValue,
  ]);

  return (
    <div className="step-1">
      {/* Step Title Field */}
      <StepTitle htmlElement="h1">
        {formData.currentTask?.singular || ''}
      </StepTitle>

      <CurrentTaskInfo />

      <p className="label-title">
        {renderTranslation(activeLocale, translations, 'step_one_label_title')}
      </p>
      <p className="label-subtitle dual-brand-text">
        {renderTranslation(activeLocale, translations, 'step_one_show_prices')}
      </p>
      <AddressField searchAddressRef={searchAddress} searchFieldRef={searchField} setAddressRef={setAddressRef} />

      <div className="jobform-partners">
        <p className="title">
          {renderTranslation(activeLocale, translations, 'step_one_little_jobs')}
        </p>
        <div className="partner-images">
          <img alt="gamma" height="60" src={jf_gammalogo} />
          <img alt="hornbach" height="60" src={jf_hornbachlogo} />
          <img alt="praxis" height="60" src={jf_praxislogo} />
          <img alt="bol" height="60" src={jf_bollogo} />
        </div>
        <p className="subtitle">
          {renderTranslation(activeLocale, translations, 'step_one_trusted')}
        </p>
      </div>
      <StepsButtons inputRef={addressRef} />
      <p className="btm-details-msg">
        {renderTranslation(activeLocale, translations, 'step_one_jobs_completed')}
      </p>
    </div>
  );
};

export default Step1;
