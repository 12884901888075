import React from 'react';
import './SmallBottomText.scss';

const SmallBottomText = ({ children, className }) => {
  return (
    <span
      className={`small-bottom-text m-top--extra-small ${className ? className.toString() : ''}`}
    >
      {children}
    </span>
  );
};

export default SmallBottomText;
