import React, { useContext, useEffect, useState } from 'react';

import StepsButtons from '../../components/StepsButtons/StepsButtons';
import StepTitle from '../../components/UI/StepTitle/StepTitle';
import DateSliderAndCalendar from '../../components/DateSliderAndCalendar/DateSliderAndCalendar';
import SmallBottomText from '../../components/UI/SmallBottomText/SmallBottomText';
import SelectDateModal from '../../components/SelectDateModal/SelectDateModal';

import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './Step-2.scss';
import { goToNextStep } from "../../utils/handleSteps";
import { StepsInfoContext } from "../../context/StepsInfoContext";

const Step2 = () => {
  const { translations, activeLocale } = useContext(TranslationsContext);

  const { formData, setFormData } = useContext(FormDataContext);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const {
    numberOfSteps: { numberOfSteps },
    stepsInfo: { currentStep, setCurrentStep },
  } = useContext(StepsInfoContext);

  // useEffect(() => {
  //   ReactGA.event({
  //     category: 'Zoofy.com Jobform Steps',
  //     action: 'Jobform Step 2 - Date and Time',
  //     nonInteraction: true,
  //   });
  // }, []);

  useEffect(() => {
    let isSlotSelected = false;
    if (formData?.taskRates?.pickedSlots?.length > 0) {
      formData.taskRates.pickedSlots?.map((singleSlot) => {
        if (singleSlot.morning || singleSlot.afternoon || singleSlot.evening) {
          isSlotSelected = true;
        }
      });
    }
    setFormData({
      ...formData,
      enableBtn: isSlotSelected,
    });
  }, [formData.taskRates.pickedSlots]);

  const openSelectedDatesModal = (e) => {
    e.preventDefault();
    if (!formData.disableMultipleRangeModal) {
      setIsModalOpened(true);
    } else {
      goToNextStep(currentStep, numberOfSteps, setCurrentStep)
    }
  };

  return (
    <div className="step-2">
      <StepTitle htmlElement="h2">
        <>
          <span>{renderTranslation(activeLocale, translations, 'choose_tree_dates')}</span>
          <span className="sub-title-text">
            {renderTranslation(activeLocale, translations, 'step_multiple_choose')}
          </span>
        </>
      </StepTitle>
      <DateSliderAndCalendar />
      <StepsButtons handleModal={(e) => openSelectedDatesModal(e)} />
      <SmallBottomText>
        {renderTranslation(activeLocale, translations, 'stats.pro_score')}
      </SmallBottomText>
      <SelectDateModal isOpened={isModalOpened} closeModal={() => setIsModalOpened(false)} />
    </div>
  );
};

export default Step2;
