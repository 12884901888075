import React, { useState, createContext, useMemo } from 'react';

// useContext hook to manage number of steps & current step global state
export const StepsInfoContext = createContext();

const StepsInfoProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [numberOfSteps, setNumberOfSteps] = useState(4);

  const resetSteps = () => {
    setCurrentStep(1);
  };

  const store = useMemo(
    () => ({
      numberOfSteps: { numberOfSteps, setNumberOfSteps },
      stepsInfo: { currentStep, setCurrentStep },
      resetSteps,
    }),
    [currentStep]
  );

  return <StepsInfoContext.Provider value={store}>{children}</StepsInfoContext.Provider>;
};

export default StepsInfoProvider;
