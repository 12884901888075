import React, { FC, useContext } from 'react';

import ErrorMessage from '../UI/ErrorMessage/ErrorMessage';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';

// Images
import uploadImage from '../../assets/zoofy/images/upload-img-icon.png';
import closeIcon from '../../assets/img/close-icon.png';
import pdfIcon from '../../assets/img/pdf-icon.png';

import './UploadedFilesBox.scss';

type FileType = {
  extension: string | string[];
  url: string | undefined;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<string>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
};

type UploadedFilesBoxType = {
  getRootProps: any;
  getInputProps: any;
  maxUploadSize: number;
  handleRemoveFile: any;
  errorMessage: string;
  isFileLoading: {
    isLoading: boolean,
    file: FileType,
  };
  isUpload: boolean;
  open: () => void,
};

const UploadedFilesBox: FC<UploadedFilesBoxType> = ({
  getRootProps,
  getInputProps,
  maxUploadSize,
  handleRemoveFile,
  errorMessage,
  isFileLoading,
  isUpload,
  open,
}) => {
  const { translations, activeLocale } = useContext(TranslationsContext);
  const { formData } = useContext(FormDataContext);

  return (
    <>
      {formData?.info?.uploads.length === 0 ? (
        isUpload ? (
          <div className="uploaded-files-box__file-container">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="uploaded-files-box m-top flex flex--column-small-gap">
            <span className="uploaded-files-box__title">
              {renderTranslation(activeLocale, translations, "step_three.upload_photo_title")}
            </span>
            <div
              {...getRootProps({
                className: "uploaded-files-box__container flex flex--column flex--center dropzone",
              })}
            >
              <div onClick={open} style={{ color: "black", gap: '2px' }}>
                <span>{renderTranslation(activeLocale, translations, "step_three.photos")}</span>
                <div className="upload-image-img">
                  <img
                    width={45}
                    height={45}
                    className="uploaded-files-box__icon"
                    src={uploadImage}
                    alt="Upload one or more Images"
                  />
                </div>
                <span>
                {renderTranslation(activeLocale, translations, "step_three.upload_photo")}
              </span>
                <span className="max-upload-size">
                {renderTranslation(activeLocale, translations, "step_three.upload_photo_max_size")}:{" "}
                  {maxUploadSize / 1048576}MB
              </span>
              </div>
              <input
                {...getInputProps()}
                id="upload-image"
                className="uploaded-files-box__input"
                name="upload-image"
              />

            </div>
          </div>
        )
      ) : (
        <div className="uploaded-files-box__images-grid m-top">
          {formData.info.uploads.map(
            (
              singleFile: {
                extension: string | string[];
                url: string | undefined;
                title:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<string>
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | null
                  | undefined;
              },
              index: React.Key | null | undefined
            ) => {
              if (
                singleFile.extension.includes("png") ||
                singleFile.extension.includes("jpg") ||
                singleFile.extension.includes("jpeg")
              ) {
                return (
                  <div key={index} className="uploaded-files-box__file-container">
                    {isFileLoading.isLoading && isFileLoading.file === singleFile ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <img
                          alt="uploaded-image"
                          className="uploaded-image"
                          src={singleFile.url}
                          onLoad={() => {
                            if (singleFile.url != null) {
                              URL.revokeObjectURL(singleFile.url);
                            }
                          }}
                        />
                        <img
                          className="close-icon"
                          width={22}
                          height={22}
                          src={closeIcon}
                          onClick={() => handleRemoveFile(singleFile)}
                          alt="Remove this Image"
                        />
                      </>
                    )}
                  </div>
                );
              } else if (singleFile.extension.includes("pdf")) {
                return (
                  <div
                    key={index}
                    className="flex flex--column flex--no-gap uploaded-files-box__file-container uploaded-files-box__file-container--pdf"
                  >
                    {isFileLoading.isLoading && isFileLoading.file === singleFile ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <img width={70} className="uploaded-pdf" src={pdfIcon} alt="uploaded-pdf"/>
                        <span className="uploaded-files-box__pdf-text">{singleFile.title}</span>
                        <img
                          className="close-icon close-icon--pdf"
                          width={22}
                          height={22}
                          src={closeIcon}
                          onClick={() => handleRemoveFile(singleFile)}
                          alt="Remove this Image"
                        />
                      </>
                    )}
                  </div>
                );
              }
            }
          )}
          {isUpload && (
            <div className="uploaded-files-box__file-container">
              <LoadingSpinner />
            </div>
          )}
          {/* The upload input field */}
          <div
            {...getRootProps({
              className:
                "uploaded-files-box__container uploaded-files-box__container--small-box flex flex--column flex--center",
            })}
          >
            <div onClick={open} style={{ color: "black" }}>
              <img
                width={45}
                height={45}
                className="uploaded-files-box__icon"
                src={uploadImage}
                alt="Upload one or more Images"
              />
            </div>
            <input
              {...getInputProps()}
              id="upload-image"
              className="uploaded-files-box__input"
              name="upload-image"
            />
          </div>
        </div>
      )}
      {errorMessage && errorMessage?.length > 0 && <ErrorMessage text={errorMessage} />}
    </>
  );
};

export default UploadedFilesBox;
