import React, { useContext } from 'react';

import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './SingleTimeCheckbox.scss';

const SingleTimeCheckbox = ({
  id,
  timeOfDayLabel,
  currentTaskRates,
  date,
  isToday,
  checked,
  handleChange,
}) => {
  const { translations, activeLocale } = useContext(TranslationsContext);

  let hours;
  switch (id) {
    case 'morning' || 'Morning':
      hours = '08:00 - 12:00';
      break;

    case 'afternoon' || 'Afternoon':
      hours = '12:00 - 18:00';
      break;

    case 'evening' || 'Evening':
      hours = '18:00 - 21:00';
      break;

    default:
      break;
  }

  let rateTypeLabel;
  switch (currentTaskRates.rateType) {
    case 'fixed price':
      rateTypeLabel = renderTranslation(activeLocale, translations, 'priceRate.fixedValue');
      break;

    case 'per hour':
      rateTypeLabel = renderTranslation(activeLocale, translations, 'priceRate.perHour');
      break;
    default:
      break;
  }

  const chosenDateObject = currentTaskRates?.dates?.filter((singleDate) => {
    return singleDate.date.substring(0, 10) === date;
  })[0];

  return (
    <div className="single-time-checkbox flex flex--center">
      <div className="checkbox-day-period">
        <label htmlFor={id} className="checkbox-container">
          <input
            type="checkbox"
            name="time-of-the-day"
            id={id}
            checked={checked}
            data-price={chosenDateObject?.price}
            onChange={handleChange}
          />
          <span className="custom-checkbox"></span>
        </label>
        <div className="flex flex--column flex--no-gap">
          <span className="single-time-checkbox__time  single-time-checkbox__time--blue">
            {!isToday
              ? timeOfDayLabel
              : renderTranslation(activeLocale, translations, 'step_two.today')}
          </span>
          {/*{!isToday && <span className="single-time-checkbox__hours">{hours}</span>}*/}
        </div>
      </div>
      <div className="single-time-checkbox__right">
        <div className="flex flex--no-gap">
          <span className="single-time-checkbox__price">
            {chosenDateObject?.price || '...'}
            &nbsp;
          </span>
          <span className="single-time-checkbox__rate">{rateTypeLabel}</span>
        </div>
        <div className="flex flex--no-gap">
          <span className="single-time-checkbox__service-fee">
            {chosenDateObject?.service_fee || '...'}
            &nbsp;
          </span>
          <span className="single-time-checkbox__service-fee">
          {renderTranslation(activeLocale, translations, 'general.service_cost')}
        </span>        </div>


      </div>
    </div>
  );
};

export default SingleTimeCheckbox;
