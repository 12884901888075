import React, { useContext, useEffect } from 'react';

import DescriptionField from '../../components/DescriptionField/DescriptionField';
import StepsButtons from '../../components/StepsButtons/StepsButtons';
import SmallBottomText from '../../components/UI/SmallBottomText/SmallBottomText';
import StepTitle from '../../components/UI/StepTitle/StepTitle';
import UploadImageField from '../../components/UploadImageField/UploadImageField';

import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './Step-3.scss';
import DynamicFields from '../../components/DynamicFields/DynamicFields';

const Step3 = () => {
  const { translations, activeLocale } = useContext(TranslationsContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    setFormData({
      ...formData,
      enableBtn: true,
    });
    //
    // ReactGA.event({
    //   category: 'Zoofy.com Jobform Steps',
    //   action: 'Jobform Step 3 - Description and Upload',
    //   nonInteraction: true,
    // });
  }, []);

  return (
    <div className="step-3">
      {/* Step Title Field */}
      <StepTitle htmlElement="h2">
        {renderTranslation(activeLocale, translations, 'Omschrijf je klus')}
        <span className="sub-title-text">
          {renderTranslation(activeLocale, translations, 'step_three.obliged')}
        </span>
      </StepTitle>

      {/* Description Field */}
      <DescriptionField />

      {/* Dynamic Field */}
      <DynamicFields />

      {/* Upload Image Field */}
      <UploadImageField />

      {/* Steps Buttons Field */}
      <StepsButtons
        handleModal={() => {
          setFormData({
            ...formData,
            enableBtn: false,
          });
        }}
      />

      {/* Small Bottom Text Field */}
      <SmallBottomText>
        {renderTranslation(activeLocale, translations, 'stats.booked_jobs')}
      </SmallBottomText>
    </div>
  );
};

export default Step3;
