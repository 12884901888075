import React, { useContext } from 'react';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';
import SmallBottomText from '../UI/SmallBottomText/SmallBottomText';

import './TermsConditions.scss';

const TermsConditions = () => {
  const { translations, activeLocale } = useContext(TranslationsContext);

  const { formData, setFormData } = useContext(FormDataContext);

  const handleChange = () => {
    setFormData((prev) => ({
      ...prev,
      enableBtn: prev.validInfo && !prev.terms,
      terms: !prev.terms,
    }));
  };

  return (
    <div className="terms-conditions-done m-top--big">
      <label htmlFor="check-terms" className="checkbox-container">
        <input
          type="checkbox"
          name="warranty-package"
          id="check-terms"
          checked={formData.terms}
          onChange={handleChange}
        />
        <span className="custom-checkbox custom-warranty-checkbox"></span>
      </label>
      <SmallBottomText className="term-title">
        {renderTranslation(activeLocale, translations, 'step_four.terms_part_one')}
        <a
          className="step-5__terms-and-condition"
          href={renderTranslation(activeLocale, translations, '/klant-voorwaarden/')}
          target="_blank"
          rel="noreferrer"
        >
          {renderTranslation(activeLocale, translations, 'algemene voorwaarden')}
        </a>
        {renderTranslation(activeLocale, translations, 'step_four.terms_part_two')}
      </SmallBottomText>
    </div>
  );
};

export default TermsConditions;
