import React, { useEffect, useContext } from 'react';

import StepTitle from '../UI/StepTitle/StepTitle';
import Button from '../UI/Button/Button';

import { FormDataContext } from '../../context/FormDataContext';
import { TranslationsContext } from '../../context/TranslationsContext';
import { renderTranslation } from '../../utils/renderTranslation';
import { StepsInfoContext } from '../../context/StepsInfoContext';

import thankYouIcon from '../../assets/img/icon-thank-you.png';

import './BookingSuccess.scss';

const BookingSuccess = () => {
  const { initForm } = useContext(FormDataContext);
  const { activeLocale, translations } = useContext(TranslationsContext);
  const { resetSteps } = useContext(StepsInfoContext);

  // When Job is finally booked send GA4 Custom Event
  useEffect(() => {
    // Send a custom event
    // ReactGA.event({
    //   category: 'Zoofy.com Jobform Steps',
    //   action: 'Jobform Success - Thank You Page',
    //   nonInteraction: true,
    // });

    const analyticsPurchase = () => {
      /*      gtag('event', 'purchase', {
        transaction_id: 'T_' + formData?.id,
        value: formData.currentTask.price,
        currency: 'EUR',
        items: [
          {
            item_id: formData.currentTask.task_id,
            item_name: formData.currentTask.singular,
            item_category: formData.currentTask.category,
            price: formData.currentTask.price,
            quantity: 1,
          },
        ],
        non_interaction: true,
      });*/
    };

    analyticsPurchase();
  }, []);

  const handleBookMore = () => {
    initForm();
    resetSteps();
    sessionStorage.clear();
  };

  return (
    <div className="booking-success">
      <div className="flex flex--center">
        <StepTitle htmlElement="h2" customClass="mb-0">
          {renderTranslation(activeLocale, translations, 'thank_you.title')}!
        </StepTitle>
        <img width={45} className="uploaded-pdf" src={thankYouIcon} alt="upload" />
      </div>

      <p className="jobform-text">
        {renderTranslation(activeLocale, translations, 'thank_you.subtitle')}
      </p>

      <p className="booking-success__book-more-text jobform-text">
        {renderTranslation(activeLocale, translations, 'thank_you.book_more')}
      </p>
      <Button handleClick={handleBookMore} className="secondary-cta">
        {renderTranslation(activeLocale, translations, 'thank_you.book_more_button')}
      </Button>
    </div>
  );
};

export default BookingSuccess;
