import React, { useContext, useEffect, useState } from 'react';

import StepsButtons from '../../components/StepsButtons/StepsButtons';
import StepTitle from '../../components/UI/StepTitle/StepTitle';
import WarrantyPackage from '../../components/WarrantyPackage/WarrantyPackage';
import CustomerInformationFields from '../../components/CustomerInformationFields/CustomerInformationFields';
import TermsConditions from '../../components/TermsConditions/TermsConditions';

import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';

import './Step-4.scss';

import idealImg from '../../assets/zoofy/images/ideal.png';
import { useMutation } from 'react-query';
import { backendCall } from '../../api/backendCall';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

const Step4 = () => {
  const { translations, activeLocale } = useContext(TranslationsContext);
  const { formData, setFormData } = useContext(FormDataContext);

  useEffect(() => {
    const { firstName, lastName, email, contactEmail, phoneNumber } = formData.userData;
    if (
      firstName &&
      lastName &&
      contactEmail &&
      /\S+@\S+\.\S+/.test(contactEmail) &&
      email &&
      /\S+@\S+\.\S+/.test(email) &&
      contactEmail === email &&
      phoneNumber?.length >= 7 &&
      phoneNumber?.length <= 13 &&
      (!isNaN(phoneNumber) || phoneNumber.charAt(0) === '+')
    )
      setFormData({
        ...formData,
        validInfo: true,
        enableBtn: formData.terms,
      });
    else
      setFormData({
        ...formData,
        validInfo: false,
        enableBtn: false,
      });
  }, [formData.userData]);

  const [pickedOptions, setPickedOptions] = useState([]);
  const [rate, setRate] = useState(0);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isExternalWebsite, setIsExternalWebsite] = useState(false);
  const [partnerName, setPartnerName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData.taskRates.pickedSlots.length > 0) {
      formatUTCDates();
      setMaximumRate();
    }
    if (formData.dynamicFields?.length > 0) formatDynamicFields();

    // ReactGA.event({
    //   category: 'Zoofy.com Jobform Steps',
    //   action: 'Jobform Step 5 - Recap',
    //   nonInteraction: true,
    // });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const partnerName = urlParams.get('ref');
    if (partnerName && partnerName !== '') {
      setIsExternalWebsite(true);
      setPartnerName(partnerName);
    } else {
      setPartnerName('No partner');
    }
  }, []);


  const formatUTCDates = () => {
    let options = [];
    formData.taskRates.pickedSlots?.map(day => {
      const dateSection = day.date;

      if (day.morning) {
        let timeStart1 = 'T07:00:00.000';
        let timeEnd1 = 'T09:00:00.000';
        let timeStart2 = 'T09:00:00.000';
        let timeEnd2 = 'T11:00:00.000';

        let option = {
          startTime: dateSection + timeStart1,
          endTime: dateSection + timeEnd1,
          rate: day.price,
        };

        option.startTime = moment(option.startTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';

        option.endTime = moment(option.endTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';

        options.push(option);

        option = {
          startTime: dateSection + timeStart2,
          endTime: dateSection + timeEnd2,
          rate: day.price,
        };
        option.startTime = moment(option.startTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';
        option.endTime = moment(option.endTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';
        options.push(option);
      }

      if (day.afternoon) {
        const timeStart1 = 'T11:00:00.000';
        const timeEnd1 = 'T13:00:00.000';

        const timeStart2 = 'T13:00:00.000';
        const timeEnd2 = 'T15:00:00.000';

        const timeStart3 = 'T15:00:00.000';
        const timeEnd3 = 'T17:00:00.000';

        let option = {
          startTime: dateSection + timeStart1,
          endTime: dateSection + timeEnd1,
          rate: day.price,
        };
        option.startTime = moment(option.startTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';
        option.endTime = moment(option.endTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';
        options.push(option);

        option = {
          startTime: dateSection + timeStart2,
          endTime: dateSection + timeEnd2,
          rate: day.price,
        };
        option.startTime = moment(option.startTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';
        option.endTime = moment(option.endTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';
        options.push(option);

        option = {
          startTime: dateSection + timeStart3,
          endTime: dateSection + timeEnd3,
          rate: day.price,
        };
        option.startTime = moment(option.startTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';
        option.endTime = moment(option.endTime)
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';
        options.push(option);
      }

      if (day.evening) {
        const timeStart1 = 'T17:00:00.000';
        const timeEnd1 = 'T20:00:00.000';

        let option = {
          startTime: dateSection + timeStart1,
          endTime: dateSection + timeEnd1,
          rate: day.price,
        };

        option.startTime = moment(option.startTime)
        // .utc()
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.startTime = option.startTime + 'Z';
        option.endTime = moment(option.endTime)
        // .utc()
        .format('YYYY-MM-DDTHH:mm:00.000');
        option.endTime = option.endTime + 'Z';

        options.push(option);
      }
    });
    setPickedOptions(options);
  };

  const setMaximumRate = () => {
    let max = 0;
    formData.taskRates.pickedSlots?.map((day) => {
      if (day.price > max) {
        max = day.price;
      }
    });
    setRate(max);
  };

  const formatDynamicFields = () => {
    let fields = [];
    formData.dynamicFields?.map((item) => {
      if (item.value) {
        const formatedField = {
          key: item.title,
          value: item.value,
        };
        fields.push(formatedField);
      }
    });
    setDynamicFields(fields);
  };

  const createAppointments = useMutation({
    mutationFn: async (body) => await backendCall(`/appointments`, 'POST', body, 'api'),
    onSuccess: (response) => {

      if (response?.data?.message) {
        NotificationManager.error('', response.data.message, 3000);
        setIsLoading(false);
        setFormData({
          ...formData,
          isBookingError: true,
        });
      } else {
        setFormData({
          ...formData,
          bookingSuccess: true,
          id: response.appointment.id,
        });
        setIsLoading(false);
      }
    },
    onError: (error) => {
      NotificationManager.error('', error, 3000);
      setIsLoading(false);
      setFormData({
        ...formData,
        isBookingError: true,
      });
    },
  });

  const handleSubmit = async (e) => {
    setIsLoading(true);

    const jobSource = isExternalWebsite ? 'iframe. Partner name: ' + partnerName : 'Zoofy.com';

    e.preventDefault();
    const body = JSON.stringify({
      data: {
        task_id: Number(formData.currentTask.task_id),
        day: new Date(),
        type: formData.isUrgentJob ? 'direct' : 'future',
        user: {
          first_name: formData.userData.firstName,
          last_name: formData.userData.lastName,
          telephone: formData.userData.phoneNumber,
          email: formData.userData.email,
          locale: activeLocale,
        },
        rate: Number(rate),
        comment: 'Via ' + jobSource + ' -> ' + formData.info.description,
        partner_id: '',
        affiliate_partner_ref: partnerName,
        caseNumber: '',
        identifier: '',
        reschedule_id: '',
        job_sources_id: isExternalWebsite ? 9 : 11,
        insurance_id: formData.warrantyPackage ? 1 : 0,
        address: {
          postal_code: formData.address.postal_code,
          suffix: formData.address.suffix,
          street: formData.address.street,
          city: formData.address.city,
          country: formData.address.country,
          lat: formData.address.lat,
          lon: formData.address.lon,
        },

        options: formData.isUrgentJob ? null : pickedOptions,
        dynamicFields,
        uuid: formData.info.uploads.length > 0 ? sessionStorage.getItem('img-uuid') : '',
        notify_whatsapp: 0,
      },
    });

    createAppointments.mutate(body);
  };

  if (isLoading) return null;

  return (
    <div className="step-4">
      {/* Step Title Field */}
      <StepTitle htmlElement="h2" customClass="step4__title">
        {renderTranslation(activeLocale, translations, 'step_four.your_details')}
      </StepTitle>

      <p className="step-title">
        {renderTranslation(activeLocale, translations, 'step_four.your_cred')}
      </p>
      <CustomerInformationFields />

      <WarrantyPackage id="warranty-package" />

      <TermsConditions />

      <StepsButtons handleSubmit={handleSubmit} />

      <div className="payment-footer-container">
        <img src={idealImg} alt="ideal" />
        <span className="payment-footer-text">
          {renderTranslation(activeLocale, translations, 'step_four.safe')}
        </span>
      </div>
    </div>
  );
};

export default Step4;
