export const getCurrentLanguage = () => {
  let currentLanguage;
  if (!window.location) {
    currentLanguage = process.env.REACT_APP_DEFAULT_LOCALE;
  } else {
    if (window.location.href.includes('de-de')) {
      currentLanguage = 'de';
    } else if (window.location.href.includes('be-nl')) {
      currentLanguage = 'nl';
    } else {
      currentLanguage = 'en';
    }
  }
  return currentLanguage;
};
