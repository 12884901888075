import React, { useContext, useEffect, useState } from 'react';
import { TranslationsContext } from '../../context/TranslationsContext.jsx';
import { FormDataContext } from '../../context/FormDataContext.jsx';
import { StepsInfoContext } from '../../context/StepsInfoContext.jsx';
import { backendCall } from '../../api/backendCall.js';
import { getCurrentLanguage } from '../../utils/getCurrentLanguage.js';
import { useQuery } from 'react-query';
import Step1 from '../../steps/Step-1/Step-1.jsx';
import Step2 from '../../steps/Step-2/Step-2.jsx';
import Step3 from '../../steps/Step-3/Step-3.jsx';
import Step4 from '../../steps/Step-4/Step-4.jsx';
import LoadingSpinner from '../UI/LoadingSpinner/LoadingSpinner.tsx';
import FormContainer from '../FormContainer/FormContainer.jsx';
import BookingSuccess from '../BookingSuccess/BookingSuccess.jsx';
import ErrorMessage from '../UI/ErrorMessage/ErrorMessage.jsx';
import { renderTranslation } from '../../utils/renderTranslation';
import MyStepper from '../Stepper/Stepper';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const JobFormScreen = () => {
  const { setTranslations, setActiveLocale } = useContext(TranslationsContext);
  const [error, setError] = useState('');
  const { formData, initForm } = useContext(FormDataContext);
  const { translations, activeLocale } = useContext(TranslationsContext);
  const {
    stepsInfo: { currentStep },
  } = useContext(StepsInfoContext);


	useEffect(() => {
		document.title = formData.currentTask?.singular || '';
	}, [formData.currentTask]);

  useEffect(() => {
    initForm();
    sessionStorage.clear();
  }, []);

  const fetchTranslations = () => {
    return backendCall(
      `/translations?_locale=${getCurrentLanguage()}&tags_contains=jobform-nl`,
      'GET',
      null,
      'gateway'
    );
  };

  const fetchTranslationsSuccess = (data) => {
    const translations = {};
    translations[getCurrentLanguage()] = { ...data };
    setTranslations(translations);
    if (getCurrentLanguage()) setActiveLocale(getCurrentLanguage());
    else setActiveLocale(process.env.REACT_APP_DEFAULT_LOCALE);
  };

  const { isLoading } = useQuery('fetchTranslations', fetchTranslations, {
    onSuccess: fetchTranslationsSuccess,
    onError: (error) => {
      setError(error);
    },
  });

  let stepComponent;

  if (currentStep === 1) {
    stepComponent = <Step1 />;
  } else if (currentStep === 2) {
    stepComponent = <Step2 />;
  } else if (currentStep === 3) {
    stepComponent = <Step3 />;
  } else if (currentStep === 4) {
    stepComponent = <Step4 />;
  }

	if (isLoading) return <LoadingSpinner/>;

	return (
		<>
			<FormContainer>
				<>
					{formData.bookingSuccess ? (
						<BookingSuccess/>
					) : (
						<>
							<p className="title">
								{renderTranslation(activeLocale, translations, 'steps_totoal_title')}
							</p>
							<MyStepper/>
							<form className="job-form">{stepComponent}</form>
						</>
					)}
					{!!error?.length && <ErrorMessage text={error}/>}
				</>
			</FormContainer>
			<NotificationContainer/>
		</>
	);
};

export default JobFormScreen;
