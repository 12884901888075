import React, { useContext } from 'react';
import { Box, StepConnector, stepConnectorClasses, StepLabel, styled } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { FormDataContext } from '../../context/FormDataContext';
import { StepsInfoContext } from '../../context/StepsInfoContext';
import { goToPrevStep } from '../../utils/handleSteps';
import StepIcon from '@mui/material/StepIcon';
import { DoneIcon } from "../../assets/img/done"; // Import the default StepIcon component
import './Stepper.scss';

const QontoConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#34b9b9',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#34b9b9',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const MyStepper = () => {
	const {
		stepsInfo: { currentStep, setCurrentStep },
	} = useContext(StepsInfoContext);
	const { formData } = useContext(FormDataContext);

	const steps = ['', '', '', ''];

	const CustomStepIcon = (props) => {
		const { completed } = props;

		return (
			<div>
				{completed ? <div className="stepper-wrapper"><DoneIcon/></div> : <StepIcon {...props} />}
			</div>
		);
	};


	return (
		<Box className="step-wrapper">
			{currentStep > 1 && !formData.bookingSuccess && (
				<div
					className="step-title__back-arrow"
					onClick={() => goToPrevStep(currentStep, setCurrentStep)}
				/>
			)}
			<div className="stepper-container" >
				<Stepper
					activeStep={currentStep - 1}
					connector={<QontoConnector/>}
					width={500}
				>
					{steps.map((label, index) => (
						<Step key={label + index} >
							<StepLabel StepIconComponent={CustomStepIcon}/>
						</Step>
					))}
				</Stepper>
			</div>
		</Box>
	);
};

export default MyStepper;
