import React, { useEffect, useState } from 'react';

import './FormContainer.scss';
import { useQueryClient } from "react-query";
import { getCurrentLanguage } from "../../utils/getCurrentLanguage";
import { setCurrentLanguage } from "../../utils/setCurrentLanguage";

const FormContainer = ({ children }) => {

	const [language, setLanguage] = useState();
	const languages = ['en', 'nl'];
	const queryClient = useQueryClient();

	useEffect(() => {
		setLanguage(getCurrentLanguage);
	}, [window])

	const onChange = (e) => {
		const lang = languages[e.target.value];
		e.preventDefault();
		setCurrentLanguage(lang);
		queryClient.invalidateQueries(['fetchTranslations']);
		setLanguage(lang);
	}


	return (
		<div className="job-form-container job-form-container--own-template">
			<div className="job-form-container__homeowner active" data-form="'jobForm">
				<>{children}
					<div className="footerWrapper">
						<select
							id="colorSelect"
							onChange={onChange}
						>
							{languages?.map((singleOption, index) => (
								<option key={index} value={`${index}`} selected={singleOption === language}>
									<span>{singleOption.toUpperCase()}</span>
								</option>
							))}
						</select>
					</div>
				</>
			</div>
		</div>
	);
};

export default FormContainer;
