import { getCurrentLanguage } from '../utils/getCurrentLanguage';
import { getOperatingCountry } from '../utils/getOperatingCountry.js';

export const backendCall = async (
  url,
  method = 'GET',
  data = null,
  urlType = 'api',
  headers = null,
  isNotDisabled = true,
  sessionId
) => {
  let baseURL;
  if (urlType === 'gateway') {
    baseURL = process.env.REACT_APP_GATEWAY_URL;
  } else if (urlType === 'media') {
    baseURL = process.env.REACT_APP_MEDIA_URL;
  } else if (urlType === 'translation') {
    baseURL = process.env.REACT_APP_TRANSLATION_URL;
  } else if (urlType === 'test-translation') {
    baseURL = process.env.REACT_APP_TEST_TRANSLATION_URL;
  } else if (urlType === 'third-party') {
    baseURL = '';
  } else {
    baseURL = process.env.REACT_APP_API_URL;
  }

  const _headers = {
    'Content-Type': 'application/json',
    'APP-ID': process.env.REACT_APP_ID,
    locale: getCurrentLanguage(),
  };

  if (urlType !== 'third-party' && isNotDisabled) {
    if (sessionStorage.getItem('operatingCountry')) {
      _headers['operating-country'] = getOperatingCountry();
    }
  }

  if (sessionId) {
    _headers['X-Autocomplete-Session'] = sessionId;
  }

  for (const header in headers) {
    _headers[header] = headers[header];
  }

  return await fetch(baseURL + url, {
    method: method,
    body: data,
    headers: _headers,
  })
    .then((response) => response.json())
    .catch((error) => {
      let _responseData = error.response.data;
      if (!_responseData.status) {
        throw 'Something went wrong, please try again';
      }
      throw _responseData.message;
    });
};
