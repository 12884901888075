import React, { useContext } from 'react';

import AddressIcon from '../../assets/img/arrow-right.png';
import { renderTranslation } from '../../utils/renderTranslation';
import { TranslationsContext } from '../../context/TranslationsContext';

import './ResultsDropdown.scss';

const ResultsDropdown = ({ name, data, opened, handleClick, loading }) => {
  const { activeLocale, translations } = useContext(TranslationsContext);

  return (
    <div
      className={`results-dropdown ${opened ? 'opened' : ''}`}
      style={opened ? { height: data?.length > 5 ? '250px' : 'max-content' } : {}}
    >
      {(
        data?.map((singleResult) => {
          const resultId = name && name === 'tasks' ? singleResult?.task_id : singleResult.id;

          const resultName =
            name && name === 'tasks'
              ? singleResult?.singular
              : singleResult?.AddressLine.replace(' -', '');
          return (
            <div
              key={resultId}
              onClick={() => handleClick(singleResult)}
              className={`results-dropdown__single-result ${
                singleResult?.task_id
                  ? 'results-dropdown__single-result--task'
                  : 'results-dropdown__single-result--address'
              }`}
            >
              <span className="jobform-main-text">{resultName}</span>

              {/* Only for Tasks Dropdown */}
              {name && name === 'tasks' && (
                <span className="results-dropdown__category jobform-text">
                  {/* Task Category, with capitalized first letter */}
                  {singleResult?.category.charAt(0).toUpperCase() + singleResult?.category.slice(1)}
                </span>
              )}

              {/* Only for Address Dropdown */}
              {singleResult?.Type && singleResult?.Type.toLowerCase() !== 'address' && (
                <img
                  width={15}
                  height={15}
                  className="address-field__info-icon"
                  src={AddressIcon}
                  alt="Get Info about Address Field"
                />
              )}
            </div>
          );
        })
      )}
      {/* Only for Tasks Dropdown */}
      {!loading && name && name === 'tasks' && data?.length === 0 && (
        <div className="results-dropdown__view-all-results">
          <a>
            {/* If there are no tasks result according to user search, show
						full text "Sorry, we could not find..." with POST API call to
						exteral bucket search. */}
            {renderTranslation(activeLocale, translations, 'step1.no-results')}
          </a>
        </div>
      )}
    </div>
  );
};

export default ResultsDropdown;
