import moment from "moment/moment";

export const makeDateArrayRange = (dataRange) => {
	if (dataRange.length === 1) return dataRange;

	const startDate = new Date(dataRange[0]);
	const endDate = new Date(dataRange[1]);
	const dateRange = [];

	for (
		let currentDate = startDate;
		currentDate <= endDate;
		currentDate.setDate(currentDate.getDate() + 1)
	) {
		dateRange.push(new Date(currentDate));
	}
	return dateRange.map((date) => moment(date).format('YYYY-MM-DD'));
};
