import './Button.scss';
import React from 'react';

const Button = ({ children, handleClick, ...otherProps }) => {
	return (
		<button onClick={handleClick} {...otherProps}>
			{children}
		</button>
	);
};

export default Button;
