import React, { useContext, useEffect, useState } from 'react';

import SingleTimeCheckbox from '../SingleTimeCheckbox/SingleTimeCheckbox';
import { TranslationsContext } from '../../context/TranslationsContext';
import { FormDataContext } from '../../context/FormDataContext';
import { renderTranslation } from '../../utils/renderTranslation';
import { getFormattedTodaysDate } from '../../utils/getFormattedDate';
import SmallBottomText from '../UI/SmallBottomText/SmallBottomText';

import './TimeCheckboxes.scss';

const TimeCheckboxes = ({ currentTaskRates, date, isVisible, isToday, showCalendar, handleSelectRange }) => {
	const { translations, activeLocale } = useContext(TranslationsContext);

	const { formData, setFormData } = useContext(FormDataContext);
	const [alreadyChosenSlot, setAlreadyChosenSlot] = useState(date.length === 1 ? formData.taskRates.pickedSlots.find((slot) => slot.date === date[0]) : undefined)

	useEffect(() => {
		setAlreadyChosenSlot(formData.taskRates.pickedSlots.find((slot) => slot.date === date[0]))
	}, [date]);

	const handleCheckboxChange = (e) => {
		const label = e.target.id;
		const isChecked = !!e.target.checked;
		const price = Number(e.target.getAttribute('data-price'));
		let updatedSlots = [];

		date.map((day) => {

			if (isToday) {
				const newSlot = {
					date: date[0],
					price: price,
				};
				newSlot[label] = isChecked;
				updatedSlots = [newSlot];
			} else {
				updatedSlots = updatedSlots.length === 0 ? formData.taskRates.pickedSlots.filter((singleSlot) => {
					return singleSlot.date !== getFormattedTodaysDate();
				}) : updatedSlots;

				const alreadyChosenSlot = updatedSlots.length === 0 ?
					formData.taskRates.pickedSlots.find((slot) => slot.date === day) :
					updatedSlots.find((slot) => slot.date === day);

				if (alreadyChosenSlot) {
					updatedSlots.map((singleSlot) => {
						if (singleSlot.date === day) {
							singleSlot[label] = isChecked;
							singleSlot.price = price;
						}
						return singleSlot;
					});
				} else {
					const newSlot = {
						date: day,
						price: price,
					};
					newSlot[label] = isChecked;
					updatedSlots.push(newSlot);
				}
			}
			setAlreadyChosenSlot(updatedSlots.find((slot) => slot.date === day));
		})

		setFormData((prev) => ({
			...prev,
			isUrgentJob: date[0] === getFormattedTodaysDate(),
			taskRates: {
				...prev.taskRates,
				pickedSlots: updatedSlots,
			},
		}));
		handleSelectRange(date);
	};

	return (
		<div className={`time-checkboxes ${isVisible && 'time-checkboxes--show m-top'} ${showCalendar && 'time-checkboxes--size'}`}>
			<SingleTimeCheckbox
				id="morning"
				timeOfDayLabel={renderTranslation(activeLocale, translations, 'step_two.morning_label')}
				currentTaskRates={currentTaskRates}
				date={date[0]}
				isToday={isToday}
				checked={!!alreadyChosenSlot?.morning}
				handleChange={handleCheckboxChange}
			/>

			{isToday && (
				<SmallBottomText className="small-bottom-text--blue">
					{renderTranslation(activeLocale, translations, 'step_two.spoed_cancel')}
				</SmallBottomText>
			)}

			{!isToday && (
				<>
					<SingleTimeCheckbox
						id="afternoon"
						timeOfDayLabel={renderTranslation(
							activeLocale,
							translations,
							'step_two.afternoon_label'
						)}
						currentTaskRates={currentTaskRates}
						date={date[0]}
						checked={!!alreadyChosenSlot?.afternoon}
						handleChange={handleCheckboxChange}
					/>
					<SingleTimeCheckbox
						id="evening"
						timeOfDayLabel={renderTranslation(activeLocale, translations, 'step_two.evening_label')}
						currentTaskRates={currentTaskRates}
						date={date[0]}
						checked={!!alreadyChosenSlot?.evening}
						handleChange={handleCheckboxChange}
					/>
				</>
			)}
		</div>
	);
};

export default TimeCheckboxes;
