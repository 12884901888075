import './LoadingSpinner.scss';
import React, { FC } from 'react';

const LoadingSpinner: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <div className={`spinner-container ${className ? className : ''}`}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
